import { useState } from 'react';
import { withRouter } from 'next/router';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { AlertModal, CustomLink } from '@components';
import URL from '@helpers/url-constant';
import { useTheme } from '@context/ThemeProvider';
import sty from './sidebar.module.scss';

function Sidebar({ handleLogout, router: { pathname } }) {
  const { clientConfig } = useTheme();
  const [logoutModal, setLogoutModal] = useState(false);

  return (
    <div className={sty['sidebar-wrap']}>
      <figure
        className={sty['campaign-reset-btn-wrap']}
        style={{ backgroundColor: clientConfig.logoBg }}
      >
        <img className={sty.logo} src={clientConfig.logo} alt="Logo Payment Loyalty" />
      </figure>
      <div className={sty['navigation-wrap']}>
        <ul className={sty['list-1.s']}>
          <li className={sty['nav-item']}>
            <CustomLink
              className={cx(sty['nav-link'], {
                [sty['nav-link-active']]: pathname.startsWith(URL.DASHBOARD),
              })}
              href={URL.DASHBOARD}
            >
              <span className={sty.counter} />
              <span className={sty['nav-name']}>Dashboard</span>
            </CustomLink>
          </li>
          <li className={sty['nav-item']}>
            <CustomLink
              className={cx(sty['nav-link'], {
                [sty['nav-link-active']]: pathname === URL.MANAGE_CAMPAIGNS,
              })}
              href={URL.MANAGE_CAMPAIGNS}
            >
              <span className={sty.counter} />
              <span className={sty['nav-name']}>Manage Campaigns</span>
            </CustomLink>
          </li>
          <li className={sty['nav-item']}>
            <CustomLink
              className={cx(sty['nav-link'], {
                [sty['nav-link-active']]: pathname === URL.MANAGE_STORES,
              })}
              href={URL.MANAGE_STORES}
            >
              <span className={sty.counter} />
              <span className={sty['nav-name']}>Manage Stores</span>
            </CustomLink>
          </li>
          <li className={sty['nav-item']}>
            <CustomLink
              className={cx(sty['nav-link'], {
                [sty['nav-link-active']]: pathname === URL.TERMS_AND_COND,
              })}
              href={URL.TERMS_AND_COND}
            >
              <span className={sty.counter} />
              <span className={sty['nav-name']}>Manage T&Cs</span>
            </CustomLink>
          </li>
        </ul>
        <ul className={sty['list-2']}>
          <li className={sty['nav-item']}>
            <CustomLink
              className={cx(sty['nav-link'], {
                [sty['nav-link-active']]: pathname === URL.SETTINGS,
              })}
              href={URL.SETTINGS}
            >
              <span className={sty.counter} />
              <span className={sty['nav-name']}>Settings</span>
            </CustomLink>
          </li>
          <li className={sty['nav-item']}>
            <button
              className={cx(sty['nav-link'], sty['logout-btn'])}
              onClick={() => setLogoutModal(true)}
            >
              <span className={sty.counter} />
              <span className={sty['nav-name']}>Logout</span>
            </button>
          </li>
        </ul>
      </div>
      {logoutModal && (
        <AlertModal
          type="2"
          msg="Are you sure you want to log out?"
          closeFn={() => setLogoutModal(false)}
          closeTxt="No"
          saveFn={handleLogout}
          saveTxt="Yes"
        />
      )}
    </div>
  );
}

Sidebar.propTypes = {
  handleLogout: PropTypes.func.isRequired,
  router: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default withRouter(Sidebar);
